<template>
  <div class="ParametersPricing">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 ParametersPricing__separator">
          <h4 class="ParametersPricing__title">{{ $t('PARAMETERS_PRICING_CREDIT_COST_TITLE') }}</h4>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('PARAMETERS_PRICING_CREDIT_COST_MODE') }}</th>
                <th scope="col">{{ $t('PARAMETERS_PRICING_CREDIT_COST_PRICE') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('PARAMETERS_PRICING_CREDIT_COST_MODE_PRINT') }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingCreditCost.PRINT.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingCreditCost.PRINT.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('PARAMETERS_PRICING_CREDIT_COST_MODE_EMBROIDERY') }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingCreditCost.EMBROIDERY.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingCreditCost.EMBROIDERY.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 class="mt-5 ParametersPricing__title">{{ $t('PARAMETERS_PRICING_INCLUDED_CREDIT_TITLE') }}</h4>
          <table class="table table-striped table-bordered">
            <tbody>
              <tr>
                <td>{{ $t('PARAMETERS_PRICING_INCLUDED_CREDIT_NUM') }}</td>
                <td>
                  <input
                    type="number"
                    class="settings-input text-right"
                    v-model="settingsPricing.includedFreeCredits"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <h4 class="mt-5 ParametersPricing__title">{{ $t('PARAMETERS_PRICING_LOGO_TITLE') }}</h4>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('FROM') }}</th>
                <th scope="col">{{ $t('TO') }}</th>
                <th scope="col">{{ $t('CREDITS') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(logoSurface, index) in settingsPricingLogoSurface" :key="'logoSurface-' + index">
                <td>
                  <input type="number" class="settings-input text-right" v-model="logoSurface.from" />
                  <span class="ParametersPricing__measure-unit"
                    >{{ $t('CM') }}
                    <sup>2</sup>
                  </span>
                </td>
                <td>
                  <input type="number" class="settings-input text-right" v-model="logoSurface.to" />
                  <span class="ParametersPricing__measure-unit"
                    >{{ $t('CM') }}
                    <sup>2</sup>
                  </span>
                </td>
                <td><input type="number" class="settings-input text-right" v-model="logoSurface.credit" /></td>
                <td>
                  <button class="Button Button__delete" @click="removeRow('settingsPricingLogoSurface', index)">
                    <Delete />
                    <span class="Button__label d-none d-xl-inline-block">{{ $t('ACTION_DELETE') }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="ParametersPricing__add-button" @click="addRow('settingsPricingLogoSurface')">
            <Plus /> {{ $t('PARAMETERS_PRICING_ADD_RULE') }}
          </button>
        </div>
        <div class="col-6">
          <h4 class="ParametersPricing__title">{{ $t('PARAMETERS_PRICING_DIGIT_TITLE') }}</h4>
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('FROM') }}</th>
                <th scope="col">{{ $t('TO') }}</th>
                <th scope="col">{{ $t('UNIT_COST') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(digital, index) in settingsPricingDigitalization" :key="'digitalization-' + index">
                <td>
                  <input type="number" class="settings-input text-right" v-model="digital.from" />
                  <span class="ParametersPricing__measure-unit"
                    >{{ $t('CM') }}
                    <sup>2</sup>
                  </span>
                </td>
                <td>
                  <input type="number" class="settings-input text-right" v-model="digital.to" />
                  <span class="ParametersPricing__measure-unit"
                    >{{ $t('CM') }}
                    <sup>2</sup>
                  </span>
                </td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="digital.unitCost.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="digital.unitCost.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                  <!-- <input type="number"
                         class="settings-input text-right"
                         v-model="digital.unitCost" /> -->
                </td>
                <td>
                  <button class="Button Button__delete" @click="removeRow('settingsPricingDigitalization', index)">
                    <Delete />
                    <span class="Button__label d-none d-xl-inline-block">{{ $t('ACTION_DELETE') }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="ParametersPricing__add-button" @click="addRow('settingsPricingDigitalization')">
            <Plus /> {{ $t('PARAMETERS_PRICING_ADD_RULE') }}
          </button>
        </div>
      </div>
      <div class="row mt-5 p-t5">
        <div class="col text-center">
          <button class="Button Button__edit ParametersPricing__save-button" @click="submitSettings()">
            <ContentSave /> {{ $t('PARAMETERS_PRICING_SAVE') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Plus from 'vue-material-design-icons/Plus.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import ContentSave from 'vue-material-design-icons/ContentSave.vue'

export default {
  name: 'parametersPricing',
  components: {
    Delete,
    Plus,
    ContentSave
  },
  computed: {
    ...mapGetters([
      'settingsPricing',
      'settingsPricingLogoSurface',
      'settingsPricingDigitalization',
      'settingsPricingCreditCost'
    ])
  },
  methods: {
    addRow(type) {
      const lastItem = this[type][this[type].length - 1] || {
        from: 0,
        to: -1
      }
      this[type].push({
        from: +lastItem.to + 1,
        to: +lastItem.to + 2,
        [type === 'settingsPricingLogoSurface' ? 'credit' : 'unitCost']:
          type === 'settingsPricingLogoSurface' ? 0 : { ITA: 0, USA: 0 }
      })
    },
    removeRow(type, index) {
      const items = this[type].filter((e, i) => i !== index)
      if (type === 'settingsPricingLogoSurface') {
        this.$store.dispatch('SETTINGS_SET_LOGO_SURFACE', items)
      } else if (type === 'settingsPricingDigitalization') {
        this.$store.dispatch('SETTINGS_SET_DIGITALIZATION', items)
      }
    },
    submitSettings() {
      const newSettings = {
        pricing: {
          ...this.settingsPricing,
          creditCost: {
            PRINT: Object.keys(this.settingsPricingCreditCost.PRINT).reduce(
              (res, e) => ({ ...res, [e]: Number(this.settingsPricingCreditCost.PRINT[e]) }),
              {}
            ),
            EMBROIDERY: Object.keys(this.settingsPricingCreditCost.EMBROIDERY).reduce(
              (res, e) => ({ ...res, [e]: Number(this.settingsPricingCreditCost.EMBROIDERY[e]) }),
              {}
            )
          },
          includedFreeCredits: Number(this.settingsPricing.includedFreeCredits),
          logoSurface: [
            ...this.settingsPricingLogoSurface.map(x =>
              Object.keys(x).reduce((res, e) => ({ ...res, [e]: Number(x[e]) }), {})
            )
          ],
          digitalization: [
            ...this.settingsPricingDigitalization.map(x =>
              Object.keys(x).reduce((res, e) => {
                if (e === 'unitCost') {
                  return {
                    ...res,
                    [e]: Object.keys(x[e]).reduce((res1, e1) => ({ ...res1, [e1]: Number(x[e][e1]) }), {})
                  }
                } else return { ...res, [e]: Number(x[e]) }
              }, {})
            )
          ]
        }
      }
      this.$store.dispatch('SETTINGS_UPDATE', newSettings)
    }
  },
  created() {
    this.$store.dispatch('SETTINGS_READ')
  }
}
</script>

<style scoped lang="scss">
.ParametersPricing {
  padding: 30px 0;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  &__separator {
    border-right: 1px solid $c-grey4;
  }
  &__add-button {
    text-align: center;
    width: 100%;
    border: 1px dashed $c-text;
    background: $c-white !important;
  }
  &__measure-unit {
    font-size: 13px;
  }
  &__save-button {
    max-width: 60%;
    width: 60%;
    height: 50px;
  }

  input.settings-input {
    width: 60px;
    padding: 0 5px;
    margin-right: 5px;
    border: 1px solid $c-grey4 !important;
    @include only-xl() {
      width: 80px;
    }
  }
}
</style>
